










































































































































import { Component, Vue } from 'vue-property-decorator';

import Button from '@/components/Button.vue';
import Header from '@/components/Header.vue';
import ImageCard from '@/components/ImageCard.vue';
import KOLSwiper from '@/components/KOLSwiper.vue';
import Swiper from '@/components/Swiper.vue';
import {
  SLIDES,
  IMAGE_CARDS,
  KOL_SLIDES,
  EnumOS,
  EnumAPP,
  EnumRegion,
  DEFAULT_REGION,
  SlideItem,
} from '@/const';
import { EnumDataTracking } from '@/const/dataTracking';
import { getOS, getRegion, openAPP } from '@/utils';

@Component({
  components: {
    Header,
    Swiper,
    ImageCard,
    Button,
    KOLSwiper,
  },
})
export default class Home extends Vue {
  slides = SLIDES;
  imageCards = IMAGE_CARDS;
  KOLSlides = KOL_SLIDES;
  EnumDataTracking = EnumDataTracking;

  EnumAPP = EnumAPP;
  OS: EnumOS = EnumOS.Others;
  region: EnumRegion = DEFAULT_REGION;

  private get urlRegion(): EnumRegion | '' {
    return (this.$route.query.region as EnumRegion) || '';
  }

  private openAPP(app: EnumAPP): void {
    openAPP(app, this.OS, this.region);
  }

  clickBanner(slide: SlideItem): void {
    const { openAppType } = slide;

    if (openAppType) {
      this.openAPP(openAppType);
    }
  }

  private async mounted(): Promise<void> {
    this.OS = getOS();
    if (this.urlRegion && Object.values(EnumRegion).includes(this.urlRegion)) {
      this.region = this.urlRegion;
      return;
    }
    const res = await getRegion();
    if (Object.values(EnumRegion).includes(res)) {
      this.region = res;
    }
  }

  goToSurvey(): void {
    window.location.href = `${window.location.href}contact-us`;
  }

  test() {
    return require('@/assets/imgs/'+this.region+'.gif')
  }
}
